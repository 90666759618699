import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import {
  CSSTransition,
  TransitionGroup,
} from 'react-transition-group';
import './App.scss';
import history from '../../history';
import Header from '../../Components/Header/Header'
import Footer from '../../Components/Footer/Footer'
import LandingPage from '../LandingPage/LandingPage'
import QuotePage from '../QuotePage/QuotePage'

function App() {
    return (
        <Router history={ history }>
            <Header />
            <Route render={({location}) => (
              <TransitionGroup>
                <CSSTransition
                  key={location.key}
                  timeout={450}
                  classNames="fade"
                >
                  <Switch location={location}>
                    <Route path="/" exact component={ LandingPage } />
                    <Route path="/quote" component={ QuotePage } />
                  </Switch>
                </CSSTransition>
              </TransitionGroup>
            )} />
            <Footer />
        </Router>
    );
}

export default App;
