import React from 'react';
import './Footer.scss';

function Footer() {
    return (
        <footer className="footer">
            &nbsp;
        </footer>
    );
}

export default Footer;
