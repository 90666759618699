import React, { useEffect } from "react";
import "./Header.scss";
import { useHistory, useLocation  } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

function Header() {
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    history.listen((location, action) => {
      window.scrollTo(0, 0)
    })
  }, [])

  const navigationBack = () => {
    history.goBack();
  };

  return (
    <header className="header">
      <nav className="navigation">
        <div className={ location.pathname === "/" ? "navigation__back-button navigation__back-button--hidden" : "navigation__back-button" } onClick={ navigationBack }>
          <ArrowBackIcon />
        </div>
      </nav>

      <h1 className="header__title">
        GAMSAT Section II <br /> Quote Generator
      </h1>
    </header>
  );
}

export default Header;
